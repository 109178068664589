/** @jsx jsx */
import { jsx, Flex, Grid } from "theme-ui"
import { darken, lighten } from "@theme-ui/color"
import React from "react"
import NavMenuDesktop from "../components/navMenuDesktop"
import NavMenuMobile from "../components/navMenuMobile"
import HomeNewsItem from "../components/homeNewsItem"
import BackgroundImg from "gatsby-background-image"
import Footer from "../components/footer"
import SEO from "../components/seo"

const TestimonialsPage = ({ data }) => {
  return (
    <>
      <SEO title="Testimonials" />
      <NavMenuMobile />
      <Grid
        gap={2}
        columns={[1, "1fr 2fr", "1fr 3fr"]}
        sx={{
          //bg: lighten("primary", 0.35), //0.35 on the red
          bg: "primaryBG",
        }}
      >
        <section>
          <NavMenuDesktop navPosition="left" />
        </section>
        <section
          sx={{
            minHeight: "100vh",
            marginRight: [3, 3, 4],
            ml: [3, 0],
            mt: ["70px", "initial"],
          }}
        >
          <h1
            sx={{
              color: "primary",
            }}
          >
            Testimonials
          </h1>
          {data.testimonials.frontmatter.grid.map(node => (
            // <HomeNewsItem production={node} key={node.id} />
            <div
              sx={{
                bg: "white",
                padding: 3,
                width: ["100", "60%"],
                marginRight: [0, "5%"],
                marginBottom: [3, 4],
                "& blockquote": {
                  color: "mutedText",
                  fontSize: 3,
                  borderLeft: "10px solid #ccc",
                  borderColor: "primary",
                  margin: "1.5em 10px",
                  padding: "0.5em 10px",
                },
                "& cite": {
                  color: "primary",
                },
              }}
            >
            {//
              /*<BackgroundImg
                fluid={node.image.childImageSharp.fluid}
                backgroundColor="#ccc"
                alt={node.job}
                sx={{
                  width: ["100%", "40%", "30%"],
                  height: ["150px", "auto"],
                }}
              />
              */
            }
              <h1>{node.job}</h1>
              <blockquote>
                "{node.quote}" <cite>{node.credit}</cite>
              </blockquote>
            </div>
          ))}
        </section>
      </Grid>
      <Footer navPosition="left" />
    </>
  )
}

export default TestimonialsPage

export const query = graphql`
  query {
    testimonials: markdownRemark(
      frontmatter: { templateKey: { eq: "page-testimonials" } }
    ) {
      id
      frontmatter {
        title
        grid {
          quote
          credit
          job
          image {
            childImageSharp {
              fluid {
                presentationWidth
                presentationHeight
                aspectRatio
              }
            }
          }
        }
      }
      html
    }
  }
`
